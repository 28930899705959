<template>
  <section>
    <v-container>
      <v-sheet color="primary" class="d-flex justify-center align-center fill-height" style="min-height: 300px;">
        <div class="white--text text-center">
          <div class="logo-background-image titleLogo" style="font-size: 64px;">
            SALT & SEA
            <div class="subtitleLogo mt-n6" style="font-size: 32px;">
              SCUBA
            </div>
          </div>
          <div class="mt-2 antonio-2">
            Frequently Asked Questions
          </div>
        </div>
      </v-sheet>
      <v-sheet color="grey lighten-2" class="mt-2">
        <div style="height: 400px;">
          <retail-images image-name="close-up-water" height="400">
            <v-overlay absolute opacity="0.4">
              <div class="white--text pa-4 text-center" style="height: 400px;">
                <div class="goBold-3">Protect yourself</div>
                <div class="mt-4">
                  <v-img contain width="175" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/dan.png" alt="Divers Alert Network"></v-img>
                </div>
              </div>
              <div class="pa-4" style="position:absolute; bottom: 0; left:0; right:0;">
                <outlined-white-button x-large block @click="doOpenExternalUrl('https://apps.dan.org/join-dan/?rc=2985942&rc=2985942')">Get Insured.</outlined-white-button>
              </div>
            </v-overlay>
          </retail-images>
        </div>
        <div style="height: 400px;" class="mt-1">
          <retail-images image-name="padi-club" height="400">
            <v-overlay absolute opacity="0.4">
              <div class="white--text pa-4 text-center" style="height: 400px;">
                <div class="goBold-3">Make the Connection</div>
                <div class="mt-4">
                  <v-img contain width="175" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/brands/padi-club.gif" alt="PADI Club"></v-img>
                </div>
              </div>
              <div class="pa-4" style="position:absolute; bottom: 0; left:0; right:0;">
                <outlined-white-button x-large block @click="doOpenExternalUrl('https://www.padi.com/padiclub?irra=28411&irrap=club')">Learn more</outlined-white-button>
              </div>
            </v-overlay>
          </retail-images>
        </div>
      </v-sheet>
    </v-container>
    <wave-effect></wave-effect>
  </section>
</template>

<script>

import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
import RetailImages from '@/components/retailImages.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'Faq',
  components: {
    WaveEffect,
    RetailImages,
    OutlinedWhiteButton
  }
}
</script>

<style scoped>
.logo-background-image {
  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Source Sans Pro", sans-serif;
  animation: reveal 3000ms ease-in-out forwards 200ms,
  glow 2500ms linear 2000ms;
}

.titleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 128px;
  letter-spacing: -2px;
}

.subtitleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-size: 64px;
  font-weight: 300;
}

@keyframes reveal {
  80%{
    letter-spacing: 4px;
  }
  100% {
    background-size: 1000% 1000%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
</style>
