<template>
  <section>
    <v-img
      :lazy-src="`https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/${imageName}-preload.jpg`"
      :src="`https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/${imageName}.jpg`"
      :width="width"
      :height="height"
      :class="`${imgClass} `"
      :alt="imageName"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <slot></slot>
    </v-img>
  </section>
</template>

<script>
export default {
  name: 'retailImages',
  props: {
    imageName: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    height: {
      type: String,
      default: () => ''
    },
    imgClass: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1263px) {
  .remove-border-radius-md {
    border-radius: 0 !important;
  }
}
</style>
